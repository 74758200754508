import React, { useState } from "react";

const Dropdown = ({ myArray, optionLabel, onOptionChange, className }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    onOptionChange(value);
  };

  return (
    <div>
      <form>
        <select
          value={selectedOption}
          onChange={handleChange}
          className={
            className
              ? className
              : "border-[1px] border-[#C2C2C2] bg-white rounded-md text-base font-medium focus:outline-none focus:ring-0"
          }
        >
          {myArray.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </form>
    </div>
  );
};

export default Dropdown;
