import React from "react";
import Sidebar from "../Sidebar/Sidebar";

const Heading = ({title, children}) => {
  return (
    <div className="flex flex-col min-h-screen w-[100%] lg:flex-row font-sf-pro-display border-t-[1px] border-t-[#C2C2C2] bg-[#F5F5F5]">
      <div>
        <Sidebar />
      </div>
      <div className="h-[100%] w-full p-5">
        <h1 className="text-4xl font-bold font-DIN-Condensed text-primary mb-8">
          {title}
        </h1>
        {children}
      </div>
    </div>
  );
};

export default Heading;
