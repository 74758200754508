import React from "react";

const TopSelling = ({ src, productName, units, title, index=1 }) => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL
  return (
    <div className="">
      <div className="absolute top-0 right-0 z-10">
        <span className="text-sm bg-[#434345] text-white font-medium p-1 flex justify-center items-center rounded-bl-md">
          {title}
        </span>
      </div>

      <div className="py-2 px-3 flex items-center border-[1px] bg-white border-[#EBEBEB] font-sf-pro-display space-x-7">
        <div className="text-2xl">{1+index} </div>
        <div>
          <img
            src={src}
            alt="product"
            className="h-fit w-fit p-2 border-[1px] border-[#E6E6E6] bg-[#F6F6F6] rounded-md"
          />
        </div>
        <div>
          <p className="text-xl text-darkslategray-300">{productName}</p>
          <p className="text-base text-darkslategray-300">Units Sold</p>
          <p className="text-[28px] text-darkslategray-300 font-medium">
            {units}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopSelling;
