import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dashboardIcon from "@/assests/images/bx-home-circle.png";
import dashboardFocusIcon from "@/assests/images/bx-home-circle-focus.png";
import analyticsIcon from "@/assests/images/analyticsLogo.png";
import analyticsFocusIcon from "@/assests/images/analytics-focus.png";
import quickBooksIcon from "@/assests/images/quickBooks.png";
import quickBooksFocusIcon from "@/assests/images/quickBooks-focus.png";
import mailChimpIcon from "@/assests/images/mailChimpMenu.png";
import mailChimpFocusIcon from "@/assests/images/mailChimp-focus.png";
import supportSystemIcon from "@/assests/images/supportSystem.png";
import supportSystemFocusIcon from "@/assests/images/supportSystem-focus.png";
import orderManagementIcon from "@/assests/images/orderManage.png";
import orderManagementFocusIcon from "@/assests/images/orderManage-focus.png";
import newDealerIcon from "@/assests/images/newDealer.png";
import newDealerFocusIcon from "@/assests/images/newDealer-focus.png";
import dealerManagementIcon from "@/assests/images/dealerManage.png";
import dealerManagementFocusIcon from "@/assests/images/dealerManage-focus.png";
import salesIcon from "@/assests/images/sales.png";
import salesFocusIcon from "@/assests/images/sales-focus.png";
import customReportsIcon from "@/assests/images/customReports.png";
import customReportsFocusIcon from "@/assests/images/customeReports-focus.png";
import dashboardCustomIcon from "@/assests/images/customization.png";
import dashboardCustomFocusIcon from "@/assests/images/customization-focus.png";
import alertsIcon from "@/assests/images/alerts.png";
import alertsFocusIcon from "@/assests/images/alerts-focus.png";
import userGuideIcon from "@/assests/images/userGuide.png";
import userGuideFocusIcon from "@/assests/images/userGuide-focus.png";
import supportAccessIcon from "@/assests/images/supportAccess.png";
import supportAccessFocusIcon from "@/assests/images/supportAccess-focus.png";
import { useAuth } from "@/AuthContext";

export default function Sidebar() {
  const {logout} = useAuth()
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [activeMyAccountLink, setActiveMyAccountLink] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    console.log("hello sidebar");
  };

  useEffect(() => {
    setActiveLink(location.pathname);
    if (
      location.pathname === "/" ||
      location.pathname === "/analytics" ||
      location.pathname === "/quickBooks" ||
      location.pathname === "/mailchimp" ||
      location.pathname === "/supportSystem" ||
      location.pathname === "/orderManagement" ||
      location.pathname === "/dealerApprovals" ||
      location.pathname === "/dealerManagement" ||
      location.pathname === "/customReports" ||
      location.pathname === "/salesTrends"||
      location.pathname === "/dashboardCustomization"||
      location.pathname === "/alerts"||
      location.pathname === "/supportAccess"||
      location.pathname === "/userGuide"||
      location.pathname === "/logout"
    ) {
      setActiveMyAccountLink(true);
    }
  }, [location]);

  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden lg:hidden z-10">
        <div className="bg-white shadow">
          <div className="container mx-auto">
            <button
              className="text-gray-500 hover:text-gray-600"
              onClick={toggleSidebar}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`absolute h-[100%] lg:relative z-10 sm:unset bg-gray-800 font-sf-pro-display text-white transition-transform mt-8 md:mt-0 lg:mt-0 ${
          sidebarOpen
            ? "transform -translate-x-full duration-300 bg-[#434345] "
            : "transform  sm:!transform !-translate-x-0 bg-[#434345]" +
              " lg:ease-in-out-none lg:duration-none"
        }`}
      >
        <div className="border-r border-r-[#C2C2C2] font-sf-pro-display text-white min-h-[100%]">
          <div className="text-center p-[30px] w-[252px] cursor-pointer">
            <div onClick={() => navigate("/")}>
              <h1
                className={`text-base flex items-center rounded-bl rounded-tl gap-2 ${
                  activeLink === "/" && " text-deepskyblue-100"
                }`}
              >
                <img
                  src={activeLink === "/" ? dashboardFocusIcon : dashboardIcon}
                  className="min-w-[20px]"
                />
                <p>Dashboard Home</p>
              </h1>
            </div>
            <div
              onClick={() => navigate("/analytics")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex text-left items-center gap-2 mt-4 rounded-bl rounded-tl ${
                  activeLink === "/analytics" && " text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/analytics"
                      ? analyticsFocusIcon
                      : analyticsIcon
                  }
                  className="min-w-[20px]"
                />
                Google Analytics & Google Ads
              </h1>
            </div>
            <div
              onClick={() => navigate("/quickBooks")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/quickBooks" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/quickBooks"
                      ? quickBooksFocusIcon
                      : quickBooksIcon
                  }
                  className="min-w-[20px]"
                />
                Quick Books
              </h1>
            </div>
            <div
              onClick={() => navigate("/mailchimp")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/mailchimp" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/mailchimp"
                      ? mailChimpFocusIcon
                      : mailChimpIcon
                  }
                  className="min-w-[20px]"
                />
                Mailchimp
              </h1>
            </div>
            <div
              onClick={() => navigate("/supportSystem")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/supportSystem" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/supportSystem"
                      ? supportSystemFocusIcon
                      : supportSystemIcon
                  }
                  className="min-w-[20px]"
                />
                Support System
              </h1>
            </div>
            <div
              onClick={() => navigate("/orderManagement")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/orderManagement" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/orderManagement"
                      ? orderManagementFocusIcon
                      : orderManagementIcon
                  }
                  className="min-w-[20px]"
                />
                Order Management
              </h1>
            </div>
            <div
              onClick={() => navigate("/dealerApprovals")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/dealerApprovals" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/dealerApprovals"
                      ? newDealerFocusIcon
                      : newDealerIcon
                  }
                  className="min-w-[20px]"
                />
                New Dealer Approvals
              </h1>
            </div>
            <div
              onClick={() => navigate("/dealerManagement")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/dealerManagement" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/dealerManagement"
                      ? dealerManagementFocusIcon
                      : dealerManagementIcon
                  }
                  className="min-w-[20px]"
                />
                Dealer Management
              </h1>
            </div>
            <div
              onClick={() => navigate("/customReports")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/customReports" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/customReports"
                      ? customReportsFocusIcon
                      : customReportsIcon
                  }
                  className="min-w-[20px]"
                />
                Custom Reports
              </h1>
            </div>
            <div
              onClick={() => navigate("/salesTrends")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/salesTrends" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/salesTrends" ? salesFocusIcon : salesIcon
                  }
                  className="min-w-[20px]"
                />
                Sales Trends
              </h1>
            </div>
            <div
              onClick={() => navigate("/dashboardCustomization")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex text-left items-center gap-2 mt-4 rounded-bl rounded-tl ${
                  activeLink === "/dashboardCustomization" &&
                  "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/dashboardCustomization"
                      ? dashboardCustomFocusIcon
                      : dashboardCustomIcon
                  }
                  className="min-w-[20px]"
                />
                Dashboard Customization
              </h1>
            </div>
            <div onClick={() => navigate("/alerts")} className="cursor-pointer">
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/alerts" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={activeLink === "/alerts" ? alertsFocusIcon : alertsIcon}
                  className="min-w-[20px]"
                />
                Notifications and Alerts
              </h1>
            </div>
            <div
              onClick={() => navigate("/userGuide")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/userGuide" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/userGuide"
                      ? userGuideFocusIcon
                      : userGuideIcon
                  }
                  className="min-w-[20px]"
                />
                User Guide
              </h1>
            </div>
            <div
              onClick={() => navigate("/supportAccess")}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/supportAccess" && "text-deepskyblue-100"
                }`}
              >
                <img
                  src={
                    activeLink === "/supportAccess"
                      ? supportAccessFocusIcon
                      : supportAccessIcon
                  }
                  className="min-w-[20px]"
                />
                Support Access
              </h1>
            </div>
            <div
              onClick={(e)=>logout()}
              className="cursor-pointer"
            >
              <h1
                className={`text-base flex items-center mt-4 gap-2 ${
                  activeLink === "/logout" && "text-deepskyblue-100"
                }`}
              >
                {/* <img
                  src={
                    activeLink === "/supportAccess"
                      ? supportAccessFocusIcon
                      : supportAccessIcon
                  }
                  className="min-w-[20px]"
                /> */}
                Logout
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
