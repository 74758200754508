import React from "react";
import messagePng from '@/assests/images/messageIcon.png'

const Feedback = ({feedback, name, time, rating}) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(<span key={i} className="text-deepskyblue-100 text-xl">&#9733;</span>); 
      } else {
        stars.push(<span key={i} className="text-xl text-[#E2E2E2]">&#9733;</span>); 
      }
    }
    return stars;
  };
  return (
    <div className="flex flex-col items-start sm:flex-row justify-between sm:items-center space-y-2 sm:space-y-0 border-b-[1px] border-[#C2C2C2] py-5">
      <img src={messagePng} alt="message icon" className="" />
      <div className="text-primary">
        <p className="text-xl ">
          {feedback}
        </p>
        <div className="text-lg">
          <span >{name}</span> |{" "}
          <span >{time}</span>{" "}
        </div>
      </div>
        <div className="flex ">
        {renderStars()}
        </div>
    </div>
  );
};

export default Feedback;
