import React, { useState } from 'react';
import Dropdown from "@/components/Dropdown/Dropdown";
import ProgressBar from "@/components/ProgressBar/ProgressBar";

export default function RealTimeOrder({handleIdChange , ids}) {
  const [selectedId, setSelectedId] = useState("");

  return (
    <div className="mt-6">
    <div className="flex justify-start items-center space-x-3">
      <h1 className="text-[28px] font-bold font-DIN-Condensed my-5 text-primary">
        Real-time Order Statuses
      </h1>
      <Dropdown
        selectedOption={selectedId}
        onOptionChange={handleIdChange}
        myArray={ids}
        optionLabel="Select Order ID"
        className={
          "border-[1px] border-[#C2C2C2] bg-white cursor-pointer rounded-md text-base font-medium"
        }
      />
    </div>

    <div className="bg-white  drop-shadow-md grid grid-cols-6 rounded-md">
      <div className=" col-span-6 lg:col-span-3 p-5">
        <div className="">
          <p className="text-lg ">
            Product Name : <span className="font-bold">Lorem Ipsum</span>
          </p>
          <p className="text-lg ">
            Order Date : <span className="font-bold">MM / DD / YY</span>
          </p>
          <p className="text-lg ">
            Current Location :<span className="font-bold">Lorem Ipsum</span>
          </p>
        </div>
        <ProgressBar />
      </div>

      {/* center div */}
      <div className=" col-span-6 sm:col-span-2 lg:col-span-1 mt-10 lg:my-0 bg-white rounded-md border-[1px] border-[#C2C2C2]">
        <div className="flex flex-col justify-center items-center p-5 border-b border-[#C2C2C2] ">
          <div className="flex flex-col justify-start items-start mb-5">
            <p className="text-[32px] font-medium text-center w-full text-deepskyblue-100">
              65%
            </p>
            <h1 className="text-base text-primary">Current Distance</h1>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center p-5 ">
          <div className="flex flex-col justify-start items-start mb-5">
            <p className="text-[32px] w-full font-medium text-center text-deepskyblue-100">
              2.5 hrs
            </p>
            <h1 className="text-base text-primary">Shipment Delay</h1>
          </div>
        </div>
      </div>

      {/* map */}
      <div className="col-span-6 min-h-[300px] sm:col-span-4 lg:col-span-2 sm:mt-10 lg:my-0 relative flex justify-center items-center">
        <button className=" absolute top-0 right-0 bg-deepskyblue-100 p-5 rounded-md text-white">
          Hrs : Mins (GMT-4)
        </button>
        <h1 className="text-base flex flex-col">
          <b className="text-center text-lg">Map</b>
          Showing Real-time Shipment Location
        </h1>
      </div>
    </div>
  </div>
  )
}
