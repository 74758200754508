import TotalCard from "@/components/Cards/TotalCard";
import React, { useEffect, useState } from "react";
import rightArrow from "@/assests/images/rightArrow.png";
import LineChart from "@/components/Charts/LineChart";
import DonutChart from "@/components/Charts/DonutChart";
import Heading from "@/components/Heading/Heading";
import TopSelling from "@/components/Cards/TopSelling";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDealerApprovals,
  fetchOrderData,
  fetchOrderHistory,
  fetchTopSelling,
} from "@/redux/apiSlice";
import {
  CURRENT_PAGE,
  CURRENT_PAGE_Ques,
  CUSTOMER_SEARCH,
  CUSTOMER_SEARCH_NOques,
  ORDER_DATA,
  ORDER_HISTORY_API,
  PAGE_SIZE,
  SORT_BY_CREATED_AT,
  TOP_SELLING_API,
} from "@/ApiRoute/apiUrls";
import RecentActivity from "@/components/orderActivity/RecentActivity";

const AdminDashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageCount, setPerPageCount] = useState(5);

  const dispatch = useDispatch();
  const topSellingData = useSelector((state) => state.myApis.topSellingRecord);
  // console.log("topSellingData", topSellingData);

  useEffect(() => {
    dispatch(fetchTopSelling(TOP_SELLING_API));
  }, []);

  useEffect(() => {
    dispatch(fetchOrderData(ORDER_DATA));
  }, []);

  const orderData = useSelector((state) => state.myApis.orderDataRecord);

  // Recent activity
  useEffect(() => {
    dispatch(
      fetchOrderHistory(
        `${
          ORDER_HISTORY_API +
          SORT_BY_CREATED_AT +
          CURRENT_PAGE +
          currentPage +
          PAGE_SIZE +
          perPageCount
        }`
      )
    );
  }, []);

  const recentActivityData = useSelector(
    (state) => state.myApis.orderHistoryRecord
  );

  // Recent customer
  useEffect(() => {
    dispatch(
      fetchDealerApprovals(
        `${
          CUSTOMER_SEARCH +
          CURRENT_PAGE +
          currentPage +
          PAGE_SIZE +
          perPageCount
        }`
      )
    );
  }, []);

  const recentCustomerData = useSelector(
    (state) => state.myApis.dealerApprovalsRecord
  );

  const cardsData = [
    {
      title: "Total Sales",
      days: "Last 30 Days",
      totalNumber: orderData?.data[0]?.total_sales || 0,
      increment: true,
    },
    {
      title: "Total Orders",
      days: "Last 30 Days",
      totalNumber: orderData?.data[0]?.order_count || 0,
      decrement: true,
    },
    {
      title: "Visits",
      days: "Last 30 Days",
      totalNumber: "15230",
      increment: true,
    },
    {
      title: "Avg. Monthly Sales",
      days: "This year",
      totalNumber: orderData?.data[0]?.average_monthly_sales_this_year,
    },
    {
      title: "Avg. Monthly Orders",
      days: "This year",
      totalNumber: Math.round(
        orderData?.data[0]?.average_monthly_orders_this_year
      ),
    },
    { title: "CSAT", days: "Day to Day", totalNumber: "85.5%" },
  ];

  return (
    <Heading title={"Dashboard Home"}>
      <div className="grid  grid-cols-6  gap-3 h-auto ">
        {/* Total sales */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 col-span-6 gap-5">
          {cardsData?.map((val, index) => (
            <TotalCard
              title={val?.title}
              days={val?.days}
              totalNumber={val?.totalNumber}
              increment={val?.increment}
              decrement={val?.decrement}
            />
          ))}
        </div>

        <div className="col-span-6 lg:col-span-6 xl:col-span-4  grid grid-cols-2 gap-3">
          {/* Top selling products */}
          <div className=" relative h-[376px]  col-span-2 custom-scrollbar  lg:col-span-1  gap-5 overflow-hidden overflow-y-scroll drop-shadow-md rounded-md">
          <div className="sticky top-0 right-20 z-10 flex justify-end items-center w-full ">
              <span className="text-sm bg-[#434345] text-white font-medium p-1 flex justify-center items-center rounded-bl-md">
                Top Selling 
              </span>
            </div>
            {topSellingData?.loading && <p>Loading...</p>}
            {topSellingData?.error && <p>Error: {topSellingData?.error}</p>}
            {topSellingData?.data && topSellingData?.data.length > 0
              ? topSellingData?.data.map((item, index) => (
                  <TopSelling
                    index={index}
                    key={item.id}
                    title={"Top Selling"}
                    src={item?.image}
                    productName={item?.name}
                    units={item?.quantity_sold}
                  />
                ))
              : !topSellingData?.loading &&
                !topSellingData?.error && <p>Data not Found</p>}
          </div>

          {/* Net Profit */}
          <div className="  col-span-2  lg:col-span-1 h-full  bg-white drop-shadow-md rounded-md p-5  flex flex-col justify-between items-start">
            <p className="text-xl text-primary font-sf-pro-display">
              NET PROFIT
            </p>
            <div className="space-y-2 w-full">
              <p className="text-lg text-primary">Annual Net Profit Target </p>
              <p className="h-2 w-full bg-deepskyblue-100 rounded-full"></p>
              <p className="text-xl text-primary font-bold">$12,000,000</p>
            </div>
            <div className="space-y-2 w-full">
              <p className="text-lg text-primary">
                Net Profit Achieved{" "}
                <span className="font-bold">(Jan - Sep)</span>{" "}
              </p>
              <p className="h-2 w-[70%] bg-[#D1D1D1] rounded-full"></p>
              <p className="text-xl text-primary font-bold">$9,070,670</p>
            </div>
            <p className="text-base text-primary">
              <span className="text-3xl text-deepskyblue-100 font-medium">
                75.41%{" "}
              </span>
              target fulfilled
            </p>
          </div>

          {/* User hit rate */}
          <div className=" min-h-[293px]  col-span-2  lg:col-span-1   bg-white  drop-shadow-md rounded-md p-5">
            <div className="flex justify-between items-center ">
              <p className="text-xl text-primary">User Hit Rate</p>
              <p className="text-sm text-primary">Last 30 Days</p>
            </div>
            <LineChart width="100%" height="90%" />
          </div>

          {/* profit loss report */}
          <div className=" min-h-[293px]  col-span-2  lg:col-span-1   bg-white flex drop-shadow-md rounded-md p-5 ">
            <div className="flex flex-col justify-between items-start space-y-2">
              <div className="">
                <p className="text-2xl text-primary font-bold">+$15,000</p>
                <p className="text-lg ">Net Income for last 30 days</p>
              </div>
              <div className="">
                <p className="text-2xl text-primary font-bold">+$17,080</p>
                <p className="text-lg ">Gross Income</p>
              </div>
              <div className="">
                <p className="text-2xl text-primary font-bold">+$1,000</p>
                <p className="text-lg ">Expenses</p>
              </div>
              <button className="text-base text-left font-bold text-primary flex justify-center items-center">
                See Profit and Loss Report{" "}
                <img src={rightArrow} alt="right arrow" className="h-fit" />
              </button>
            </div>
            <div className="flex justify-center items-center">
              <DonutChart height="80%" width="80%" />
            </div>
          </div>
        </div>

        {/* Recent activity */}
        <div className="  max-h-[730px] min-h-[300px] custom-scrollbar  col-span-6  xl:col-span-2 xl:!h-full bg-white drop-shadow-md rounded-md p-5 overflow-hidden overflow-y-scroll">
          <p className="text-xl text-primary">Recent Activity</p>
          {recentActivityData?.loading && <p>Loading...</p>}
          {recentActivityData?.error && (
            <p>Error: {recentActivityData?.error}</p>
          )}
          {recentActivityData?.data &&
          recentActivityData?.data?.items?.length > 0
            ? recentActivityData?.data?.items.map((item, index) => (
                <RecentActivity
                  key={index}
                  item={item.items[0].name}
                  // description={"description "}
                  time={item.created_at}
                />
              ))
            : !recentActivityData.loading &&
              !recentActivityData?.error && <p>Data Not Found</p>}

          {/* Recent customer */}
          {recentCustomerData?.data &&
          recentCustomerData?.data?.items?.length > 0
            ? recentCustomerData?.data?.items.map((item, index) => (
                <RecentActivity
                  key={index}
                  item={item.firstname +' ' +item.lastname }
                  time={item.created_at}
                />
              ))
            : !recentCustomerData.loading &&
              !recentCustomerData?.error && <p>Data Not Found</p>}
        </div>
      </div>
    </Heading>
  );
};

export default AdminDashboard;
