import React from 'react';
import { Chart } from 'react-google-charts';

const data = [
  ['', ''],
  ['A', 75],
  ['B', 25],
];

const options = {
  pieHole: 0.7,
  pieSliceText: 'none',
  legend: 'none',
  backgroundColor: 'transparent',
  chartArea: { width: '100%', height: '100%' },
  slices: {
    0: { color: '#00C0F3' },  // Blue color
    1: { color: '#D1D1D1' },  // Gray color
  },
};

const DonutChart = () => {
  return (
    <div className='flex justify-center items-center'>

      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width="200px"
        height="200px"
        
        />
        </div>
  );
};

export default DonutChart;
