import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import ProtectedRoute from './PrivateRoute';
import AdminDashboard from "@/pages/AdminDashboard";
import GoogleAnalytics from "@/pages/GoogleAnalytics";
import QuickBooks from "./pages/QuickBooks";
import MailChimp from "./pages/MailChimp";
import SupportSystem from "./pages/SupportSystem";
import OrderManagement from "./pages/OrderManagement/index";
import DealerApprovals from "./pages/DealerApprovals";
import DealerManagement from "./pages/DealerManagement";
import CustomReports from "./pages/CustomReports";
import SalesTrends from "./pages/SalesTrends";
import DashboardCustomization from "./pages/DashboardCustomization";
import NotificationAlerts from "./pages/NotificationAlert";
import SupportAccess from "./pages/SupportAccess";
import UserGuide from "./pages/UserGuide";
import Login from './pages/Login';
import { useAuth } from './AuthContext'


const Routes = () => {
  const { auth } = useAuth();

  const routes = useRoutes([
    { path: '/login', element: auth ? <Navigate to="/" /> : <Login /> },
    { path: '/', element: (<ProtectedRoute> <AdminDashboard />  </ProtectedRoute>) },
    { path: '/analytics', element: (<ProtectedRoute> <GoogleAnalytics />  </ProtectedRoute>) },
    { path: '/quickBooks', element: (<ProtectedRoute> <QuickBooks />  </ProtectedRoute>) },
    { path: '/mailchimp', element: (<ProtectedRoute> <MailChimp /></ProtectedRoute>) },
    { path: '/supportSystem', element: (<ProtectedRoute> <SupportSystem /> </ProtectedRoute>) },
    { path: '/orderManagement', element: (<ProtectedRoute> <OrderManagement /> </ProtectedRoute>) },
    { path: '/dealerApprovals', element: (<ProtectedRoute> <DealerApprovals /> </ProtectedRoute>) },
    { path: '/dealerManagement', element: (<ProtectedRoute> <DealerManagement /> </ProtectedRoute>) },
    { path: '/customReports', element: (<ProtectedRoute> <CustomReports /> </ProtectedRoute>) },
    { path: '/salesTrends', element: (<ProtectedRoute> <SalesTrends /> </ProtectedRoute>) },
    { path: '/dashboardCustomization', element: (<ProtectedRoute> <DashboardCustomization /> </ProtectedRoute>) },
    { path: '/alerts', element: (<ProtectedRoute> <NotificationAlerts /> </ProtectedRoute>) },
    { path: '/supportAccess', element: (<ProtectedRoute> <SupportAccess /> </ProtectedRoute>) },
    { path: '/userGuide', element: (<ProtectedRoute> <UserGuide /> </ProtectedRoute>) },
    { path: '/', element: <Navigate to="/dashboard" /> },
  ]);
  return routes;
};

export default Routes;