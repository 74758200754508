import React from "react";

const RecentActivity = ({ item, description='', time }) => {
  return (
    <div className="p-5 border-b-[1px] border-[#EBEBEB] space-y-2">
      <div className="text-lg text-primary font-bold flex justify-between">
        {item}
        <span className="text-sm font-bold">{time}</span>
      </div>
      
      {description && <p className="text-[16px] text-primary">
        {description}
      </p> }
    </div>
  );
};

export default RecentActivity;
