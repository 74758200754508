import HeadingCard from "@/components/Cards/HeadingCard";
import ComparisonChart from "@/components/Charts/ComparisonChart";
import DonutChart from "@/components/Charts/DonutChart";
import Heading from "@/components/Heading/Heading";
import React from "react";

const CustomReports = () => {
  return (
    <Heading title={"Custom Reports"}>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5">

        {/* Net Profit vs Prior Year (USD) */}
        <div className="col-span-2">
          <div className="flex justify-between items-center">
            <p className="font-bold font-DIN-Condensed text-[28px] text-primary mb-5">
              Net Profit vs Prior Year (USD)
            </p>
            <div className="flex justify-between items-center space-x-5">
              <div className="flex justify-center items-center space-x-2">
                <p className="h-2 w-[37px] rounded-full bg-deepskyblue-100"></p>
                <p className="text-xl font-sf-pro-display text-primary">
                  This year
                </p>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <p className="h-2 w-[37px] rounded-full bg-[#F46A6A]"></p>
                <p className="text-xl font-sf-pro-display text-primary">
                  Last year
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white drop-shadow-md rounded-md">
            <ComparisonChart />
          </div>
        </div>

        {/* Gross Income vs Expenses (USD) */}
          <HeadingCard cardTitle={"Gross Income vs Expenses (USD)"} height={"h-[80%]"}>
            <div className="flex justify-between items-center ">
              <div className="flex flex-col">
                <div className="flex justify-start items-center space-x-2">
                  <p className="h-2 w-4 bg-deepskyblue-100 rounded-full"></p>
                  <p className="text-xl font-sf-pro-display text-primary">
                    Income
                  </p>
                </div>
                <p className="text-xl font-bold text-primary font-sf-pro-display">
                  $19,500,000
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-start items-center space-x-2">
                  <p className="h-2 w-4 bg-[#C2C2C2] rounded-full"></p>
                  <p className="text-xl font-sf-pro-display text-primary">
                    Expenses
                  </p>
                </div>
                <p className="text-xl font-bold text-primary font-sf-pro-display">
                  $450,000
                </p>
              </div>
            </div>
            <div className="flex justify-center items-center h-[80%]">
              <DonutChart />
            </div>
          </HeadingCard>

        {/* Net Profit Fulfillment (USD) */}
        <HeadingCard cardTitle={"Net Profit Fulfillment (USD)"} height={"h-[80%]"}>
          <p className="text-xl text-primary font-sf-pro-display">NET PROFIT</p>
          <div className="space-y-2 w-full">
            <p className="text-lg text-primary">Annual Net Profit Target </p>
            <p className="h-2 w-full bg-deepskyblue-100 rounded-full"></p>
            <p className="text-xl text-primary font-bold">$12,000,000</p>
          </div>
          <div className="space-y-2 w-full">
            <p className="text-lg text-primary">
              Net Profit Achieved <span className="font-bold">(Jan - Sep)</span>{" "}
            </p>
            <p className="h-2 w-[70%] bg-[#D1D1D1] rounded-full"></p>
            <p className="text-xl text-primary font-bold">$9,070,670</p>
          </div>
          <p className="text-base text-primary">
            <span className="text-3xl text-deepskyblue-100 font-medium">
              75.41%{" "}
            </span>
            target fulfilled
          </p>
        </HeadingCard>
      </div>

      {/* row-2 */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10">
        {/* graph */}
        <div className="">
          <div className="flex justify-between items-center">
            <p className="font-bold font-DIN-Condensed text-[28px] text-primary mb-5">
              Gross Profit vs Prior Year (USD)
            </p>
            <div className="flex justify-between items-center h-[80%] space-x-5">
              <div className="flex justify-center items-center space-x-2">
                <p className="h-2 w-[37px] rounded-full bg-deepskyblue-100"></p>
                <p className="text-xl font-sf-pro-display text-primary">
                  This year
                </p>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <p className="h-2 w-[37px] rounded-full bg-[#F46A6A]"></p>
                <p className="text-xl font-sf-pro-display text-primary">
                  Last year
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white drop-shadow-md rounded-md">
            <ComparisonChart />
          </div>
        </div>

        {/* Gross Income vs Expenses (USD) */}
          <HeadingCard cardTitle={"Gross Income vs Expenses (USD)"} height={"h-[90%]"}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 text-primary">
              <div className=" flex justify-start space-x-5 items-center p-5">
                <p className="h-12 w-[3px] bg-deepskyblue-100 rounded-full"></p>
                <div className="flex leading-none flex-col">
                  <p className="text-xl font-medium">Total Visits</p>
                  <p className="text-[32px] font-medium">125500</p>
                </div>
              </div>
              <div className=" flex justify-start space-x-5 items-center p-5">
                <p className="h-12 w-[3px] bg-deepskyblue-100 rounded-full"></p>
                <div className="flex leading-none flex-col">
                  <p className="text-xl font-medium">Visits This Month</p>
                  <p className="text-[32px] font-medium">15230</p>
                </div>
              </div>
              <div className=" flex justify-start space-x-5 items-center p-5">
                <p className="h-12 w-[3px] bg-deepskyblue-100 rounded-full"></p>
                <div className="flex leading-none flex-col">
                  <p className="text-xl font-medium">Total Registered Users</p>
                  <p className="text-[32px] font-medium">15000</p>
                </div>
              </div>
              <div className=" flex justify-start space-x-5 items-center p-5">
                <p className="h-12 w-[3px] bg-deepskyblue-100 rounded-full"></p>
                <div className="flex leading-none flex-col">
                  <p className="text-xl font-medium">
                    New Registrations This Month
                  </p>
                  <p className="text-[32px] font-medium">650</p>
                </div>
              </div>
              <div className=" flex justify-start space-x-5 items-center p-5">
                <p className="h-12 w-[3px] bg-deepskyblue-100 rounded-full"></p>
                <div className="flex leading-none flex-col">
                  <p className="text-xl font-medium">Total Units Sold</p>
                  <p className="text-[32px] font-medium">87500</p>
                </div>
              </div>
              <div className=" flex justify-start space-x-5 items-center p-5">
                <p className="h-12 w-[3px] bg-deepskyblue-100 rounded-full"></p>
                <div className="flex leading-none flex-col">
                  <p className="text-xl font-medium">Units Sold This Month</p>
                  <p className="text-[32px] font-medium">1895</p>
                </div>
              </div>
            </div>
          </HeadingCard>
        </div>
    </Heading>
  );
};

export default CustomReports;
