export const TOP_SELLING_API = "/rest/V1/topselling/data";

export const SORT_BY_NAME_DESC =
  "&searchCriteria[sortOrders][0][field]=firstname&searchCriteria[sortOrders][0][direction]=DESC";

export const SORT_BY_NAME_ASC =
  "&searchCriteria[sortOrders][0][field]=firstname&searchCriteria[sortOrders][0][direction]=ASC";

export const PAGE_SIZE_and = "searchCriteria[pageSize]=";

export const PAGE_SIZE = "&searchCriteria[pageSize]=";

export const CURRENT_PAGE = "&searchCriteria[current_page]=";

export const CURRENT_PAGE_Ques = "?searchCriteria[current_page]=";

export const FILTER_FIELDS_PAGINATION = `&fields=items[id,firstname,lastname,custom_attributes,gender],total_count,page_size,current_page,search_criteria`;

export const CUSTOMER_SEARCH = "/rest/V1/customers/search?";

export const GET_INVENTORY = '/rest/V1/inventory/source-items?searchCriteria'
export const ORDER_DATA = "/rest/V1/order/data";

export const DEALER_APPROVAL_API = '&searchCriteria[filter_groups][0][filters][0][value]=0&searchCriteria[filter_groups][0][filters][0][field]=is_dealer'

export const ORDER_HISTORY_API = `/rest/V1/orders`
export const SORT_BY_CREATED_AT = '?searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=DESC'

