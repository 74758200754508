import DealerCustomizationCard from "@/components/Cards/DealerCustomizationCard";
import redSquare from "../assests/images/redSquare.svg";
import Heading from "@/components/Heading/Heading";
const DashboardCustomization = () => {
  return (
    <>
      <Heading title={"Dashboard Customization"}>
        <div className="flex  flex-col md:flex-row  gap-2 lg:gap-8 mr-4 ">
          <div className="flex gap-2 items-center justify-center">
            <p>Show My Templates</p>
            <label className="relative inline-flex items-center cursor-pointer mt-4 xl:mt-0">
              <input type="checkbox" className="sr-only peer" />
              <div
                className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                                        peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 
                                        peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                                        after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border 
                                        after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 bg-[#C2C2C2] peer-checked:bg-[#00C0F3]"
              ></div>
            </label>
          </div>

          <div className="flex gap-2 items-center">
            <p>Show Default Templates</p>
            <label className="relative inline-flex items-center cursor-pointer mt-4 xl:mt-0">
              <input type="checkbox" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 bg-[#C2C2C2] peer-checked:bg-[#00C0F3]"></div>
            </label>
          </div>
        </div>
        <div className="flex-col my-4    ">
          <div className="flex justify-center items-end">

          <h1 className="text-[28px] text-primary font-DIN-Condensed  font-bold">
            Dashboard Template{" "}
          </h1>
          <hr className=" hidden md:flex w-full text-[#C2C2C2]" />
          </div>
          <div className="flex flex-col justify-center flex-wrap lg:flex lg:flex-row gap-8 mr-[12px] ">
            <DealerCustomizationCard
              Heading={"Standard Dashbord"}
              subHeading={
                "Lorem ipsum dolor sit amet consectetur. Viverra posuere in netus porttitor sit sit aliquet vulputate."
              }
              button1={"+ Add DashBoard"}
              button2={"Preview/Edit"}
            />
            <DealerCustomizationCard
              Heading={"Sales Dashbord"}
              subHeading={
                "Lorem ipsum dolor sit amet consectetur. Viverra posuere in netus porttitor sit sit aliquet vulputate."
              }
              button1={"+ Add DashBoard"}
              button2={"Preview/Edit"}
            />
            <DealerCustomizationCard
              Heading={"Analytics Dashbord"}
              subHeading={
                "Lorem ipsum dolor sit amet consectetur. Viverra posuere in netus porttitor sit sit aliquet vulputate."
              }
              button1={"+ Add DashBoard"}
              button2={"Preview/Edit"}
            />
            <div className="flex justify-center">
              <div className="    flex-col max-w-[360px]  shadow-md p-4 my-6 bg-[white]">
                <div className="flex flex-row gap-4 py-2 text-[28px] ">
                  <img src={redSquare} />
                  <h1>Custom Dashboard </h1>
                </div>
                <div className="py-2 text-[18px]">
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Viverra posuere in
                    netus porttitor sit sit aliquet vulputate.
                  </p>
                </div>
                <div className=" flex flex-col  lg:flex  lg:flex-row    ">
                  <button className="border border-[#C2C2C2] rounded-[6px] w-full p-3 text-start">
                    {" "}
                    + Create Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mr-6">
          <button className="bg-[#00C0F3] px-4 rounded-[30px] p-1 text-[white]">
            View All
          </button>
        </div>
      </Heading>
    </>
  );
};
export default DashboardCustomization;
