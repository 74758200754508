// export default const Extra =

export const options = [
  { value: "dealer", label: "Dealer" },
  { value: "consumer", label: "Consumer" },
];
export const recent = [{ value: "recent", label: "Recent" }];

export const ids = [
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
  { label: "#FSHD678" },
];

export const columns = [
  { header: "DEALER NAME", accessor: "customer_firstname" },
  {
    header: "Order ID",
    accessor: "order_id",
    arrayCount: 1,
    nextP: "items",
    obj: false,
  },
  {
    header: "Product",
    accessor: "name",
    arrayCount: 1,
    nextP: "items",
    obj: false,
  },
  {
    header: "Quantity",
    accessor: "qty_ordered",
    arrayCount: 1,
    nextP: "items",
    obj: false,
  },
  {
    header: "Order Date",
    accessor: "created_at",
    arrayCount: 1,
    nextP: "items",
    obj: false,
  },
  {
    header: "Payment Method",
    accessor: "method",
    arrayCount: 1,
    obj: true,
    nextP: "payment",
  },
  {
    header: "Billing Amount",
    accessor: "amount_paid",
    arrayCount: 1,
    obj: true,
    nextP: "payment",
  },
  { header: "Order Status", accessor: "status" },
  { header: "Payment Status", accessor: "state" },
  { header: "Details", accessor: "actionBtn", lable: "View" },
];
