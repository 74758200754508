import NewDealer from "@/components/Cards/NewDealer";
import Dropdown from "@/components/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import manImg from "@/assests/images/man.png";
import Heading from "@/components/Heading/Heading";
import { number } from "prop-types";
import { getAddress, findObjectValue } from "@/Utility/CommonFunction";
import PaginationComp from "@/components/Pagination/PaginationComp";
import {
  CURRENT_PAGE,
  CUSTOMER_SEARCH,
  DEALER_APPROVAL_API,
  PAGE_SIZE,
} from "@/ApiRoute/apiUrls";
import { useDispatch, useSelector } from "react-redux";
import { fetchDealerApprovals } from "@/redux/apiSlice";

const DealerApprovals = () => {
  const [selectedOrder, setSelectedOrder] = useState("");
  const [approved, setApproved] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [perPageCount, setPerPageCount] = useState(2);
  const dispatch = useDispatch();

  const handleOrderChange = (value) => {
    setSelectedOrder(value);
  };
  const order = [{ label: "Recent First" }, { label: "Older First" }];

  const dealerApprovalsData = useSelector(
    (state) => state.myApis.dealerApprovalsRecord
  );
  // console.log("666666", dealerApprovalsData);

  useEffect(() => {
    dispatch(
      fetchDealerApprovals(
        `${
          CUSTOMER_SEARCH +
          PAGE_SIZE +
          perPageCount +
          CURRENT_PAGE +
          currentPage +
          DEALER_APPROVAL_API
        }`
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchDealerApprovals(
        `${
          CUSTOMER_SEARCH +
          PAGE_SIZE +
          perPageCount +
          CURRENT_PAGE +
          currentPage +
          DEALER_APPROVAL_API
        }`
      )
    );
  }, [currentPage, perPageCount]);

  useEffect(() => {
    const data = dealerApprovalsData?.data;
    if (data) {
      setApproved(data?.items);
      setTotalCount(data?.total_count);
      setCurrentPage(data?.search_criteria?.current_page);
    }
  }, [dealerApprovalsData?.data?.total_count]);

  const getPhone = (data) => {
    const attribute = "phone_number";
    return findObjectValue(data, attribute);
  };

  const getImg = (data) => {
    let image = manImg;
    const attribute = "profile_pic";
    const img = findObjectValue(data, attribute);
    if (img) {
      image = img;
    }
    return image;
  };

  const handlePageClick = (event) => {
    console.log("event.selected", event.selected);
    setCurrentPage(event.selected + 1);
  };

  const handlePaginationDrop = (value) => {
    setPerPageCount(value);
  }

  const getOptions = () => {
    const optionsCount = Array.from({ length: 5 }, (_, index) => ({
      label: (index + 1) * 10,
      value: (index + 1) * 10,
    }));
    return optionsCount
  }

  return (
    <Heading title={"New Dealer Approvals"}>
      <div className="bg-white rounded-md drop-shadow-md p-5">
        {/* sorting */}
        <div className="flex justify-start items-center mb-5">
          <p className=" text-primary text-lg font-bold mr-5">Sorting</p>
          <Dropdown
            selectedOption={selectedOrder}
            onOptionChange={handleOrderChange}
            myArray={order}
            optionLabel="Date "
            className={
              " bg-deepskyblue-100 cursor-pointer border-none text-white rounded-md text-base font-medium"
            }
          />
        </div>

        {/* cards */}

        {dealerApprovalsData?.data?.items?.length > 0
          ? dealerApprovalsData?.data?.items.map((dealer) => (
              <NewDealer
                src={getImg(dealer?.custom_attributes)}
                firstname={dealer.firstname}
                lastname={dealer.lastname}
                email={dealer.email}
                phone={getPhone(dealer?.custom_attributes)}
                address={getAddress(dealer?.addresses)}
                applicationDate={dealer?.created_at}
                message={
                  "Lorem ipsum dolor sit amet consectetur. Viverra posuere in netus porttitor sit sit aliquet vulputate. Quis sed odio magna odio consequat quis consectetur ac hac. Elit sapien pretium arcu vestibulum amet a nulla. Ac felis eget nisi tellus ultricies ultrices."
                }
              />
            ))
          : dealerApprovalsData?.loading
          ? "...Loading"
          : !dealerApprovalsData?.loading &&
            dealerApprovalsData?.data?.items?.length == 0 &&
            "Data not Found"}

        {/* pagination */}
        <div className="flex flex-col sm:flex-row justify-between w-full items-center sm:space-x-3 space-y-3 sm:space-y-0">
        <div className="flex flex-col xs:flex-row justify-between " >
        <Dropdown
            selectedOption={perPageCount}
            onOptionChange={(e) => handlePaginationDrop(e)}
            myArray={getOptions()}
            optionLabel="Select Order ID"
            className={
              "border-[1px] border-[#C2C2C2] bg-white cursor-pointer rounded-md text-base font-medium"
            }
          />
          <p className="text-xl text-primary ml-4 mt-2">
            Showing <span className="font-bold"> {approved?.length} / {totalCount}</span> items per page
          </p>
          </div>
        <PaginationComp handlePageClick={handlePageClick} totalCount={totalCount} perPageCount={perPageCount} />
        </div>
      </div>
    </Heading>
  );
};

export default DealerApprovals;
