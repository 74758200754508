import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const REACT_APP_MAILCHIMP_API_KEY = 'http://localhost//mailchimp.php?url=' //`https://${process.env.REACT_APP_MAILCHIMP_DC}.api.mailchimp.com/3.0`;
const MAILCHIMP_TOKEN = process.env.REACT_APP_MAILCHIMP

const headers = {
  Authorization: `Bearer ${token}`
}
const headersMailchimpToken = {
  Authorization: `Bearer ${MAILCHIMP_TOKEN}`
}

// const mailchimpApi = axios.create({
//   baseURL: `https://${process.env.REACT_APP_MAILCHIMP_DC}.api.mailchimp.com/3.0`,
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${process.env.REACT_APP_MAILCHIMP_API_KEY}`
//   }
// });


export const get = async (endpoint) => {
  try {
    const res = await axios.get(`${BASE_URL}${endpoint}`, {
      headers: headers
    });
    return res.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const post = async (endpoint, data) => {
  try {
    const res = await axios.post(`${BASE_URL}${endpoint}`, data, {
      headers: headers
    });
    return res.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getMailchimp = async (endpoint, data='') => {
  try {
    const res = await axios.get(REACT_APP_MAILCHIMP_API_KEY+endpoint,{
      // headers: headersMailchimpToken
    });
    return res.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};