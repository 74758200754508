import React, { useState } from "react";
import fastArrowPng from "@/assests/images/fastArrowPng.png";
import timePng from "@/assests/images/clockPng.png";
import indicatorPng from "@/assests/images/indicatorPng.png";
import Table from "@/components/Tables/Table";
import Feedback from "@/components/Cards/Feedback";
import Heading from "@/components/Heading/Heading";
import HeadingCard from "@/components/Cards/HeadingCard";

const SupportSystem = () => {
  const [data, setData] = useState([
    { order_id: "#000123", order_id: "order_id", name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000124", accessor: "200", name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000125", accessor: "300",  name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000125", accessor: "300",  name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000125", accessor: "300",  name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000125", accessor: "300",  name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000125", accessor: "300",  name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000125", accessor: "300",  name:'test', product: 'test', qty:"100" , date:"2024-08-09" },

  ]);

  const columns = [
    { header: "DEALER NAME", accessor: "name" },
    { header: "Order ID", accessor: "order_id" },
    { header: "Product", accessor: "product" },
    { header: "Quantity", accessor:  "qty" },
    { header: "Order Date", accessor: "date" },

  ];

  return (
    <Heading title={"Support System Stats"}>
      <div className="flex flex-col space-y-10 p-5">
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
          {/* tickets */}
          <div className="">
            <h1 className="text-[28px] font-DIN-Condensed font-bold text-primary mb-5">
              Recipients{" "}
              <span className="text-[28px] font-light font-DIN-Condensed text-primary">
                - Today
              </span>
            </h1>
            <div className="grid grid-cols-2 h-[286px] bg-white drop-shadow-md rounded-md py-2  px-5">
              <div className="flex flex-col justify-center items-center p-5 border-r border-b border-[#EBEBEB]">
                <p className="text-xl text-primary">Raised Tickets</p>
                <p className="text-5xl font-medium text-primary">32</p>
              </div>
              <div className="flex flex-col justify-center items-center p-5 border-l border-b border-[#EBEBEB]">
                <p className="text-xl text-primary">Solved Tickets</p>
                <p className="text-5xl font-medium text-primary">20</p>
              </div>
              <div className="flex flex-col justify-center items-center p-5 border-r border-t border-[#EBEBEB]">
                <p className="text-xl text-primary">Open Tickets</p>
                <p className="text-5xl font-medium text-primary">15</p>
              </div>
              <div className="flex flex-col justify-center items-center p-5 border-l border-t border-[#EBEBEB]">
                <p className="text-xl text-primary">Unassigned Tickets</p>
                <p className="text-5xl font-medium text-primary">4</p>
              </div>
            </div>
          </div>

          {/* responses n resolution times */}
          <HeadingCard
            cardTitle={"Response & Resolution Times"}
            height={"h-[286px]"}
          >
            <div className="flex flex-col rounded-md  mt-3">
              <div className="flex justify-center h-full items-center pb-5 border-b-2 border-[#EBEBEB]">
                <img
                  src={fastArrowPng}
                  alt="fast arrow "
                  className="h-fit mr-5"
                />
                <div className="flex flex-col justify-center items-center  ">
                  <p className="text-[40px] font-medium text-primary">
                    45m 30s
                  </p>
                  <p className="text-xl text-primary">
                    Average First Response Time
                  </p>
                </div>
              </div>
              <div className="flex justify-center p-5 h-full items-center">
                <img src={timePng} alt="fast arrow " className="h-fit mr-5" />

                <div className="flex flex-col justify-center items-center ">
                  <p className="text-[40px] font-medium text-primary">20</p>
                  <p className="text-xl text-primary">
                    Average Full Resolution Time
                  </p>
                </div>
              </div>
            </div>
          </HeadingCard>

          {/* customer score */}
          <HeadingCard
            cardTitle={"Customer Satisfaction Score"}
            height={"h-[286px]"}
          >
            <div className="flex justify-center items-center h-[90%]">
              <div className="flex flex-col justify-center items-center">
                <p className="text-xl text-primary">CSAT</p>
                <p className="text-[40px] font-medium text-primary">85.5%</p>
              </div>
              <div className="flex justify-center items-center">
                <img src={indicatorPng} alt="fast arrow " className="h-fit" />
              </div>
            </div>
          </HeadingCard>
        </div>

        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
          {/* Table */}
          <div className="">
            <h1 className="text-[28px]  font-DIN-Condensed font-bold text-[#434345]">
              Campaign Results
            </h1>
            <div className="w-[100%] h-[371px] overflow-y-auto mt-5 custom-scrollbar">
              <Table
                columns={columns}
                rows={data}
                largeScreenClass={"hidden md:block"}
                smallScreenClass={"block md:hidden"}
                borderHeaderStyle={
                  "py-2 px-4 border-b  border-[#EBEBEB] text-left text-base lg:text-lg font-bold"
                }
                borderStyle={
                  "py-2 px-4 border-b  border-[#EBEBEB] text-sm lg:text-base text-left"
                }
              />
            </div>
          </div>

          {/* customer feedback */}
          <HeadingCard cardTitle={"Customer Feedback"} height={"h-[371px]"}>
            <div className=" overflow-y-auto h-full custom-scrollbar">
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={4}
              />
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={5}
              />
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={5}
              />
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={5}
              />
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={5}
              />
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={5}
              />
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={5}
              />
              <Feedback
                feedback={"Thank you for exchanging my item so promptly."}
                name={"Samuel Philips"}
                time={" 1 hr ago"}
                rating={5}
              />
            </div>
          </HeadingCard>

        </div>
      </div>
    </Heading>
  );
};

export default SupportSystem;
