import React from 'react';

function ProgressBar() {
    return (
        <div className="flex items-center justify-center mt-10">
            {/* Step 1 */}
            <div className="flex items-center relative">
                <div className="w-5 h-5 flex items-center justify-center rounded-full bg-deepskyblue-100 text-white"></div>
                <span className="text-[#545454] absolute top-7   text-smi font-sf-pro-display">Step1</span>
            </div>
            <div className="flex-auto border-t-2 border-deepskyblue-100"></div>
            {/* Step 2 */}
            <div className="flex items-center relative">
                <div className="w-5 h-5 flex items-center justify-center rounded-full bg-deepskyblue-100 text-white"></div>
                <span className="text-[#545454] absolute top-7 text-smi font-sf-pro-display">Step2</span>
            </div>
            <div className="flex-auto border-t-2 border-deepskyblue-100"></div>
            {/* Step 3 */}
            <div className="flex items-center relative">
                <div className="w-5 h-5 flex items-center justify-center rounded-full bg-deepskyblue-100 text-white"></div>
                <span className="text-[#545454] absolute top-7 text-smi font-sf-pro-display">Step3</span>
            </div>
            <div className="flex-auto border-t-2 border-[#C2C2C2]"></div>
            {/* Step 4 */}
            <div className="flex items-center relative">
                <div className="w-5 h-5 flex items-center justify-center rounded-full bg-[#C2C2C2] text-white"></div>
                <span className="text-[#545454] absolute top-7 text-smi font-sf-pro-display text-gray-500">Step4</span>
            </div>
            <div className="flex-auto border-t-2 border-[#C2C2C2]"></div>
            {/* Step 5 */}
            <div className="flex items-center relative">
                <div className="w-5 h-5 flex items-center justify-center rounded-full bg-[#C2C2C2] text-white"></div>
                <span className="text-[#545454] absolute top-7 text-smi font-sf-pro-display text-gray-500">Step5</span>
            </div>
            <div className="flex-auto border-t-2 border-[#C2C2C2]"></div>
            {/* Final Step */}
            <div className="flex items-center relative">
                <div className="w-5 h-5 flex items-center justify-center rounded-full bg-[#C2C2C2] text-white"></div>
                <span className="text-[#545454] absolute top-7 text-smi font-sf-pro-display text-gray-500">Final Step</span>
            </div>
        </div>
    );
}

export default ProgressBar;
