import React from "react";
import { Chart } from "react-google-charts";

const LineChart = ({ width, height }) => {
  // Generate wiggly data points
  const data = [["x", "Value"]];
  for (let i = 0; i <= 10; i += 0.1) {
    data.push([i, Math.sin(i) + Math.sin(2 * i)]);
  }

  const options = {
    hAxis: {
      gridlines: { color: "#ebebeb" },
      textPosition: 'out',
      titleTextStyle: {
        color: '#333',
        fontSize: 12
      }
    },
    vAxis: {
      gridlines: { color: "#ebebeb" },
      textPosition: 'out',
      titleTextStyle: {
        color: '#333',
        fontSize: 12
      }
    },
    legend: { position: "none" },
    colors: ["#00BFFF"],
    lineWidth: 3,
    chartArea: { width: "100%", height: "100%" },
  };

  return (
    <Chart
      chartType="LineChart"
      width={width}
      height={height}
      data={data}
      options={options}
    />
  );
};

export default LineChart;
