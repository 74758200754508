import React from "react";

const STATUS = {
  VIEW: 0,
};

const Table = ({
  columns,
  rows,
  largeScreenClass,
  smallScreenClass,
  borderHeaderStyle,
  borderStyle,
  handleButton,
}) => {
  return (
    <div className="w-[100%]">
      {/* Table for larger screens */}
      <div className={largeScreenClass ? largeScreenClass : "hidden lg:block"}>
        <table className="min-w-full drop-shadow-md bg-white text-primary p-2 rounded-md font-sf-pro-display">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className={
                    borderHeaderStyle
                      ? borderHeaderStyle
                      : "py-2 px-4 border-b border-r border-[#EBEBEB]  text-right text-base lg:text-lg font-bold"
                  }
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {rows?.length > 0
              ? rows.map((row, rowIndex) => (
                  <tr key={rowIndex} className="overflow-y-auto">
                    {columns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className={
                          borderStyle
                            ? borderStyle
                            : "py-2 px-4 border-b border-r border-[#EBEBEB] text-sm lg:text-base text-right"
                        }
                      >
                        {column?.arrayCount === 1 && column?.obj === true ? (
                          row[column.nextP][column.accessor]
                        ) : column?.arrayCount === 1 &&
                          column?.obj === false ? (
                          row[column.nextP]?.map((val) => val[column.accessor])
                        ) : column.accessor === "actionBtn" ? (
                          <>
                            <button
                              type="button"
                              className="bg-deepskyblue-100 p-1 px-3 text-white rounded-md"
                              onClick={(e) =>
                                handleButton(
                                  STATUS.VIEW,
                                  row?.items[0]?.order_id
                                )
                              }
                            >
                              {" "}
                              {column.lable}{" "}
                            </button>
                          </>
                        ) : (
                          row[column.accessor]
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
      </div>

      {/* Cards for smaller screens */}
      <div className={smallScreenClass ? smallScreenClass : "block lg:hidden"}>
        {rows?.length > 0
          ? rows.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className="drop-shadow-md bg-white text-primary p-4 rounded-md font-sf-pro-display mb-4"
              >
                {columns.map((column, colIndex) => (
                  <div
                    key={colIndex}
                    className="mb-2 flex justify-between items-center"
                  >
                    <div className="font-bold text-sm text-gray-500">
                      {column.header}
                    </div>
                    <div className="text-sm lg:text-base">
                      {column?.arrayCount === 1 && column?.obj === true ? (
                        row[column.nextP][column.accessor]
                      ) : column?.arrayCount === 1 && column?.obj === false ? (
                        row[column.nextP]?.map((val) => val[column.accessor])
                      ) : column.accessor === "actionBtn" ? (
                        <>
                          <button
                            type="button"
                            className="bg-deepskyblue-100 p-1 px-3 text-white rounded-md"
                            onClick={(e) =>
                              handleButton(STATUS.VIEW, row?.items[0]?.order_id)
                            }
                          >
                            {column.lable}
                          </button>
                        </>
                      ) : (
                        row[column.accessor]
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))
          : ""}
      </div>

    </div>
  );
};

export default Table;
