import greenSquare from '../../assests/images/greenSquare.svg'

const DealerCustomizationCard = ({ Heading, subHeading, button1, button2 }) => {
    return (
        <>
            <div className='flex justify-center '>
                <div className="flex-col max-w-[380px] shadow-md p-4 my-6 bg-[white] ">
                    <div className=" flex flex-row gap-4 py-2 text-[28px]">
                        <img src={greenSquare} />
                        <h1>{Heading}    </h1>
                    </div>
                    <div className="py-2 text-[18px]">
                        <p>{subHeading}</p>
                    </div>
                    <div className=" flex flex-col gap-4  lg:flex-row  lg:justify-between">
                        <button className="border border-[#C2C2C2] rounded-[6px]  px-4 py-2 text-[18px] text-[#434345]"> {button1}</button>
                        <button className={` rounded-[6px] p-3 px-6 bg-[#00C0F3]  border-[#00C0F3] text-white text-[18px] text-[#434345]`} > {button2}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DealerCustomizationCard;