import LineChart from "@/components/Charts/LineChart";
import React, { useState } from "react";
import mouseImg from "@/assests/images/mouse.png";
import pieChartImg from "@/assests/images/pie-chart-alt.png";
import Table from "@/components/Tables/Table";
import Dropdown from "@/components/Dropdown/Dropdown";
import Heading from "@/components/Heading/Heading";

const GoogleAnalytics = () => {
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [data, setData] = useState([
    { order_id: "#000123", order_id: "order_id", name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000124", accessor: "200", name:'test', product: 'test', qty:"100" , date:"2024-08-09" },
    { order_id: "#000125", accessor: "300",  name:'test', product: 'test', qty:"100" , date:"2024-08-09" },

  ]);

  const columns = [
    { header: "DEALER NAME", accessor: "name" },
    { header: "Order ID", accessor: "order_id" },
    { header: "Product", accessor: "product" },
    { header: "Quantity", accessor:  "qty" },
    { header: "Order Date", accessor: "date" },

  ];

  const months = [
    { label: "January" },
    { label: "February" },
    { label: "March" },
    { label: "April" },
    { label: "May" },
    { label: "June" },
    { label: "July" },
    { label: "August" },
    { label: "September" },
    { label: "October" },
    { label: "November" },
    { label: "December" },
  ];

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = 2020; year <= currentYear; year++) {
    years.push({ value: year, label: year });
  }

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  return (
    <Heading title={" Google Analytics & Google Ads"}>
      {/* Date */}
      <div className="flex flex-col justify-end items-end absolute top-5 right-5">
        <div className="flex justify-end  text-lg text-primary font-bold mb-2">
          <p>{selectedMonth}</p>
          {","}
          <p>{selectedYear}</p>
        </div>
        <div className="flex justify-center">
          <Dropdown
            selectedOption={selectedMonth}
            onOptionChange={handleMonthChange}
            myArray={months}
            optionLabel="Select Month"
            className={
              "drop-shadow-md bg-white rounded-l-md text-base font-medium"
            }
          />
          <Dropdown
            selectedOption={selectedYear}
            onOptionChange={handleYearChange}
            myArray={years}
            optionLabel="Select Year"
            className={
              "drop-shadow-md bg-white rounded-r-md text-base font-medium"
            }
          />
        </div>
      </div>
      <div className="xl:h-screen space-y-5 mt-16">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
          {/* Chart */}
          <div className="h-[100%]">
            <p className="text-[28px] font-DIN-Condensed font-bold text-primary mb-2">
              User Hit Rate
            </p>
            <div className="bg-white drop-shadow-md rounded-md h-[90%]">
              <LineChart height="100%" width="100%" />
            </div>
          </div>

          {/* Stats */}
          <div className="">
            <p className="text-[28px] font-DIN-Condensed font-bold text-primary mb-2">
              Conversion Stats
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5 h-[90%]">
              <div className="bg-white drop-shadow-md rounded-md p-5 h-full flex flex-col justify-center items-center space-y-5">
                <p className="text-xl text-darkslategray-300 text-center">
                  Total Interactions
                </p>
                <img src={mouseImg} alt="" className="" />
                <p className="text-[64px] text-darkslategray-300">7686</p>
              </div>
              <div className="bg-white drop-shadow-md rounded-md p-5 h-full flex flex-col justify-center items-center space-y-5">
                <p className="text-xl text-darkslategray-300 text-center">
                  Total Conversions
                </p>
                <img src={mouseImg} alt="" className="" />
                <p className="text-[64px] text-darkslategray-300">145</p>
              </div>
              <div className="bg-white drop-shadow-md rounded-md p-5 h-full flex flex-col justify-center items-center space-y-5">
                <p className="text-xl text-darkslategray-300 text-center">
                  Conversion Rate
                </p>
                <img src={pieChartImg} alt="" className="" />
                <p className="text-[64px] text-darkslategray-300">10%</p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <h1 className="text-[28px]  font-DIN-Condensed font-bold text-[#434345] mt-10">
            Campaign Results
          </h1>
          <div className="w-[100%] h-[378px] overflow-y-auto mt-2">
            <Table
              columns={columns}
              rows={data}
              largeScreenClass={"hidden xl:block"}
              smallScreenClass={"block xl:hidden"}
            />
          </div>
        </div>
      </div>
    </Heading>
  );
};

export default GoogleAnalytics;
