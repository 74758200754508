export const getAddress = (data) => {
  if (!data || !data.length) return "";

  const fullAddress = data[0];
  let addressParts = [];

  if (fullAddress?.street && fullAddress.street.length) {
    addressParts.push(...fullAddress.street);
  }
  if (fullAddress?.city) {
    addressParts.push(fullAddress.city);
  }
  if (fullAddress?.region?.region) {
    addressParts.push(fullAddress.region.region);
  }
  if (fullAddress?.postcode) {
    addressParts.push(fullAddress.postcode);
  }
  if (fullAddress?.country_id) {
    addressParts.push(fullAddress.country_id);
  }

  const address = addressParts.join(", ");
  console.log("Full address:", address);
  return address;
};


export const findObjectValue = (data, attribute)=>{
  let finalValue= '';
  const findData = data.find((item)=> item?.attribute_code == attribute);
  if(![undefined, null].includes(findData?.value) && findData?.value !== 0){
    finalValue = findData?.value;
  }
  return finalValue
}