import React from "react";

const NewDealer = ({
  src,
  firstname,
  lastname,
  email,
  phone,
  address,
  applicationDate,
  message,
}) => {
  return (
    <div className=" flex flex-col md:flex-row justify-between items-start font-sf-pro-display py-5 pb-10 border-b border-b-[#EBEBEB]">
      <div className="flex flex-col sm:flex-row w-full md:max-w-[80%]">
        <img src={src} alt="dealer image" className="h-[150px] w-[150px] rounded-full mb-5 sm:mb-0 mr-7" />
        <div className=" ">
          <div className="text-2xl text-primary font-bold mb-2">{firstname}{" "}{lastname}</div>
          <div className="text-lg text-primary font-bold">
            {" "}
            Email: <span className="font-normal"> {email}</span>
          </div>
          <div className="text-lg text-primary font-bold">
            {" "}
            Phone No.: <span className="font-normal">{phone}</span>
          </div>
          <div className="text-lg text-primary font-bold">
            {" "}
            Address: <span className="font-normal"> {address}</span>
          </div>
          <div className="text-lg text-primary font-bold">
            {" "}
            Application Date:{" "}
            <span className="font-normal"> {applicationDate} </span>
          </div>
          <div className="text-lg text-primary font-bold flex flex-col">
            {" "}
            Message:{" "}
            <span className="font-normal text-justify leading-tight"> {message}</span>
          </div>
        </div>
      </div>
      <div className="w-full md:w-fit">
        <button className="bg-deepskyblue-100 mt-5 md:mt-0 text-white text-lg w-full md:w-full rounded-full px-3 py-1">
          View Details
        </button>
      </div>
    </div>
  );
};

export default NewDealer;
