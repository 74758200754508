import HeadingCard from "@/components/Cards/HeadingCard";
import SalesChart from "@/components/Charts/SalesChart";
import Heading from "@/components/Heading/Heading";
import React, { useEffect, useState } from "react";
import brushImg from "@/assests/images/long-hair-dogs.png";
import Dropdown from "@/components/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopSelling } from "@/redux/apiSlice";

const SalesTrends = () => {
  const [selectedTimeframe, setSelectedTimeframe] = useState("");

  const dispatch = useDispatch();
  const topSellingData = useSelector((state) => state.myApis.topSellingRecord);
  // console.log("topSellingData", topSellingData);
  useEffect(() => {
    dispatch(fetchTopSelling(`/rest/V1/topselling/data`));
  }, []);

  const timeframe = [
    { value: "7_days", label: "Last 7 Days" },
    { value: "30_days", label: "Last 30 Days" },
    { value: "90_days", label: "Last 90 Days" },
  ];

  const handleTimeframeChange = (value) => {
    setSelectedTimeframe(value);
  };
  return (
    <Heading title={"Sales Trends"}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-h-screen">
        <div className="grid grid-cols-1 space-y-5">
          {/* Performance Metrics */}
          <div>
            <h1 className="text-[28px] font-DIN-Condensed font-bold mb-5 text-primary">
              Performance Metrics
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-5 h-[80%]">
              <div className="bg-white drop-shadow-md rounded-md p-5 flex flex-col justify-center items-center">
                <p className="text-xl text-primary">Total Sales</p>
                <p className="text-[32px] text-deepskyblue-100">$9,47,500</p>
                <p className="text-sm text-primary">This Month</p>
              </div>
              <div className="bg-white drop-shadow-md rounded-md p-5 flex flex-col justify-center items-center">
                <p className="text-xl text-primary">Total Orders</p>
                <p className="text-[32px] text-deepskyblue-100">1895</p>
                <p className="text-sm text-primary">This Month</p>
              </div>
              <div className="bg-white drop-shadow-md rounded-md p-5 flex flex-col justify-center items-center">
                <p className="text-xl text-primary">Falied Deliveries</p>
                <p className="text-[32px] text-deepskyblue-100">0</p>
                <p className="text-sm text-primary">This Month</p>
              </div>
              <div className="bg-white drop-shadow-md rounded-md p-5 flex flex-col justify-center items-center">
                <p className="text-xl text-primary">Avg. Monthly Sales</p>
                <p className="text-[32px] text-deepskyblue-100">$5,29,500</p>
              </div>
              <div className="bg-white drop-shadow-md rounded-md p-5 flex flex-col justify-center items-center">
                <p className="text-xl text-primary">Avg. Monthly Orders</p>
                <p className="text-[32px] text-deepskyblue-100">1059</p>
              </div>
              <div className="bg-white drop-shadow-md rounded-md p-5 flex flex-col justify-center items-center">
                <p className="text-xl text-primary">Returns</p>
                <p className="text-[32px] text-deepskyblue-100">18</p>
              </div>
            </div>
          </div>

          {/* Sales Data Over Time */}
          <HeadingCard cardTitle={"Sales Data Over Time"} height={"h-[80%]"}>
            <SalesChart />
          </HeadingCard>
        </div>

        {/* Top selling  */}
        <div className="relative h-screen">
          <div className="flex justify-between items-center">
            <span className="text-[28px] font-DIN-Condensed font-bold mb-5 w-full text-primary flex">
              Top Products
            </span>
            <span className="w-full flex justify-end items-center text-lg font-sf-pro-display">
              <Dropdown
                selectedOption={selectedTimeframe}
                onOptionChange={handleTimeframeChange}
                myArray={timeframe}
                optionLabel="Last 30 Days"
                className={
                  " border-none text-lg bg-[#F5F5F5] text-primary cursor-pointer"
                }
              />
            </span>
          </div>
          {/* Top selling */}
          <div className=" relative h-[90%] bg-white drop-shadow-md rounded-md overflow-hidden overflow-y-scroll custom-scrollbar">
            <div className="sticky top-0 right-20 z-10 flex justify-end items-center">
              <span className="text-base bg-deepskyblue-100 text-white font-medium p-2 rounded-bl-md">
                Top Selling Product
              </span>
            </div>

            {/* products */}
            {topSellingData?.loading && <p>Loading...</p>}
            {topSellingData?.error && <p>Error: {topSellingData?.error}</p>}
            {topSellingData?.data && topSellingData?.data.length > 0
              ? topSellingData?.data.map((item) => (
                  <div className="p-5 flex items-center font-sf-pro-display space-x-7 border border-b-[1px] border-[#EBEBEB]">
                    <div className="text-[32px] font-light text-primary">1</div>
                    <div className="w-[159px] h-[159px] border-[1px] border-[#E6E6E6] bg-[#F6F6F6] rounded-md flex justify-center items-center">
                      <img
                        src={item?.image}
                        alt="product"
                        className="h-fit w-fit "
                      />
                    </div>
                    <div className="space-y-3">
                      <p className="text-2xl text-primary">{item?.name}</p>
                      <p className="text-xl text-primary">Units Sold</p>
                      <p className="text-4xl text-deepskyblue-100 font-medium">
                        {item?.quantity_sold}
                      </p>
                    </div>
                  </div>
                ))
              : !topSellingData?.loading &&
                !topSellingData?.error && <p>Data not Found</p>}
            <div className="text-lg sticky bg-white bottom-0 text-primary border-t-[1px] m-2 pt-3 flex justify-center items-center border-[#E6E6E6]">
              Showing product ranking based on number of units sold
            </div>
          </div>
        </div>
      </div>
    </Heading>
  );
};

export default SalesTrends;
