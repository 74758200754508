import React from "react";
import supportAccess from "@/assests/images/customerSupport.png";
import rightArrowIcon from "@/assests/images/rightArrorwIcon.png";
import Heading from "@/components/Heading/Heading";
import HeadingCard from "@/components/Cards/HeadingCard";

const SupportAccess = () => {
  return (
    <Heading title={"Support Access"}>
      <HeadingCard cardTitle={"Access Our Support Portal"}>
        <div className=" p-8 h-screen">
          <p className="text-[#74788D] text-lg">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
            voluptates in sed, ipsum animi perferendis dolores recusandae, ipsa
            quidem quam delectus maiores assumenda impedit optio totam
            molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Eligendi impedit explicabo debitis
            laboriosam iste consequuntur accusamus cum, nobis repellendus, ipsum
            quibusdam pariatur expedita cumque minus.
          </p>
          <div className="flex">
            <img src={supportAccess} alt="supportAccess" className="mt-4" />
            <div className="ml-10">
              <p className="font-bold font-sf-pro-display text-3xl text-primary mt-4 mb-2">
                24 x 7 <span className="text-lg">Support</span>
              </p>
              <button className="flex justify-center items-center font-medium text-lg text-[#74788D] rounded-full rounded-tl-none border-l-4 border-b-2 border p-3 pl-4 pr-6 border-deepskyblue-100 ">
                Link for Support Channel goes here
                <img
                  src={rightArrowIcon}
                  alt="rightArrow"
                  className="ml-5 h-fit"
                />
              </button>
            </div>
          </div>
        </div>
      </HeadingCard>
    </Heading>
  );
};

export default SupportAccess;
