import { get } from "@/ApiRoute/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  topSellingRecord: {
    data: [],
    loading: false,
    error: null,
  },
  dealerManagementRecord: {
    data: [],
    loading: false,
    error: null,
  },
  orderDataRecord :{
    data: [],
    loading: false,
    error: null,
  },
  dealerApprovalsRecord:{
    data: [],
    loading: false,
    error: null,
  },
  orderHistoryRecord:{
    data: [],
    loading: false,
    error: null,
  },
  
};

// Top selling data
export const fetchTopSelling = createAsyncThunk("get", async (API) => {
  try {
    const getRecordData = await get(API);
    return getRecordData;
  } catch (error) {
    throw error;
  }
});

// Dealer management data
export const fetchDealerManagemet = createAsyncThunk("getDealer", async (API) => {
  try {
    const dealerData = await get(API);
    return dealerData;
  } catch (error) {
    throw error;
  }
});

// Order data dashboard
export const fetchOrderData = createAsyncThunk("getOrderData", async (API) => {
  try {
    const orderData = await get(API);
    // console.log(orderData)
    return orderData;
  } catch (error) {
    throw error;
  }
});

// Dealer approvals
export const fetchDealerApprovals = createAsyncThunk("getDealerApprovals", async (API) => {
  try {
    const dealerApprovals = await get(API);
    // console.log(dealerApprovals)
    return dealerApprovals;
  } catch (error) {
    throw error;
  }
});


// Order History
export const fetchOrderHistory = createAsyncThunk("getOrderHistory", async (API) => {
  try {
    const orderHistory = await get(API);
    // console.log(orderHistory, "")
    return orderHistory;
  } catch (error) {
    throw error;
  }
});

const apiSlice = createSlice({
  name: "getApis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopSelling.pending, (state) => {
        state.topSellingRecord.loading = true;
        state.topSellingRecord.error = null;
      })
      .addCase(fetchTopSelling.fulfilled, (state, action) => {
        state.topSellingRecord.loading = false;
        state.topSellingRecord.data = action.payload;
      })
      .addCase(fetchTopSelling.rejected, (state, action) => {
        state.topSellingRecord.loading = true;
        state.topSellingRecord.error = action.error.message;
      })
      .addCase(fetchDealerManagemet.pending, (state) => {
        state.dealerManagementRecord.loading = true;
        state.dealerManagementRecord.error = null;
      })
      .addCase(fetchDealerManagemet.fulfilled, (state, action) => {
        state.dealerManagementRecord.loading = false;
        state.dealerManagementRecord.data = action.payload;
      })
      .addCase(fetchDealerManagemet.rejected, (state, action) => {
        state.dealerManagementRecord.loading = true;
        state.dealerManagementRecord.error = action.error.message;
      })
      .addCase(fetchOrderData.pending, (state) => {
        state.orderDataRecord.loading = true;
        state.orderDataRecord.error = null;
      })
      .addCase(fetchOrderData.fulfilled, (state, action) => {
        state.orderDataRecord.loading = false;
        state.orderDataRecord.data = action.payload;
      })
      .addCase(fetchOrderData.rejected, (state, action) => {
        state.orderDataRecord.loading = true;
        state.orderDataRecord.error = action.error.message;
      })
      .addCase(fetchDealerApprovals.pending, (state) => {
        state.dealerApprovalsRecord.loading = true;
        state.dealerApprovalsRecord.error = null;
      })
      .addCase(fetchDealerApprovals.fulfilled, (state, action) => {
        state.dealerApprovalsRecord.loading = false;
        state.dealerApprovalsRecord.data = action.payload;
      })
      .addCase(fetchDealerApprovals.rejected, (state, action) => {
        state.dealerApprovalsRecord.loading = true;
        state.dealerApprovalsRecord.error = action.error.message;
      })
      .addCase(fetchOrderHistory.pending, (state) => {
        state.orderHistoryRecord.loading = true;
        state.orderHistoryRecord.error = null;
      })
      .addCase(fetchOrderHistory.fulfilled, (state, action) => {
        state.orderHistoryRecord.loading = false;
        state.orderHistoryRecord.data = action.payload;
      })
      .addCase(fetchOrderHistory.rejected, (state, action) => {
        state.orderHistoryRecord.loading = true;
        state.orderHistoryRecord.error = action.error.message;
      })
      
  },
});

// export const getTop = (state) => state?.getApis?.topSell ;
// export const getLoading = (state) => state?.getApis?.loading;
// export const getError = (state) => state?.getApis?.error;

export default apiSlice.reducer;
