import React from "react";
import { Chart } from "react-google-charts";

const SalesChart = () => {
  const data = [
    ["Day", "Sales"],
    [1, 500],
    [2, 500],
    [3, 500],
    [4, 500],
    [5, 500],
    [6, 500],
    [7, 500],
    [8, 500],
    [9, 500],
    [10, 500],
    [11, 500],
    [12, 500],
    [13, 500],
    [14, 500],
    [15, 500],
    [16, 500],
    [17, 500],
    [18, 500],
    [19, 500],
    [20, 500],
    [21, 500],
    [22, 500],
    [23, 500],
    [24, 500],
    [25, 500],
    [26, 500],
    [27, 500],
    [28, 500],
    [29, 500],
    [30, 500],
  ];

  const options = {
    title: "$500.00",
    subtitle: "Total Sales",
    hAxis: { title: "Last 30 Days" },
    vAxis: { title: "Sales", minValue: -1000, maxValue: 1000 },
    legend: { position: "right" },
  };

  return (
    <div >
      <Chart
        chartType="LineChart"
        width="100%"
        height="100%"
        data={data}
        options={options}
      />
    </div>
  );
};

export default SalesChart;
