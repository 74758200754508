import React, { useState } from "react";
import accordionIcon from "@/assests/images/accordionIcon.png";
import Heading from "@/components/Heading/Heading";
import HeadingCard from "@/components/Cards/HeadingCard";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className=" p-8 h-[75vh] overflow-y-auto custom-scrollbar text-primary font-DIN-Condensed">
      <h3
        onClick={toggleAccordion}
        className="cursor-pointer font-sf-pro-display text-lg font-medium mb-4 flex items-center"
      >
        {title} <img src={accordionIcon} alt="accordionIcon" className="ml-4" />
      </h3>
      {isOpen && (
        <div className="text-primary font-sf-pro-display text-sm h-[100%]">{children}</div>
      )}
    </div>
  );
};

const UserGuide = () => {
  return (
    <Heading title={" User Guide"}>
      <HeadingCard cardTitle={" How to use each part of the admin panel ..."}>
        <Accordion title="Google Analytics & Google Ads">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
            voluptates in sed, ipsum animi perferendis dolores recusandae, ipsa
            quidem quam delectus maiores assumenda impedit optio totam
            molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Eligendi impedit explicabo debitis
            laboriosam iste consequuntur accusamus cum, nobis repellendus, ipsum
            quibusdam pariatur expedita cumque minus.
          </p>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam delectus maiores assumenda impedit optio totam
              molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eligendi impedit explicabo debitis
              laboriosam iste consequuntur accusamus cum, nobis repellendus,
              ipsum quibusdam pariatur expedita cumque minus.
            </p>
          </div>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam delectus maiores assumenda impedit optio totam
              molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eligendi impedit explicabo debitis
              laboriosam iste consequuntur accusamus cum, nobis repellendus,
              ipsum quibusdam pariatur expedita cumque minus.
            </p>
          </div>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam delectus maiores assumenda impedit optio totam
              molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eligendi impedit explicabo debitis
              laboriosam iste consequuntur accusamus cum, nobis repellendus,
              ipsum quibusdam pariatur expedita cumque minus.
            </p>
          </div>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam delectus maiores assumenda impedit optio totam
              molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eligendi impedit explicabo debitis
              laboriosam iste consequuntur accusamus cum, nobis repellendus,
              ipsum quibusdam pariatur expedita cumque minus.
            </p>
          </div>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam Esse!
            </p>
          </div>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam delectus maiores assumenda impedit optio totam
              molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eligendi impedit explicabo debitis
              laboriosam iste consequuntur accusamus cum, nobis repellendus,
              ipsum quibusdam pariatur expedita cumque minus.
            </p>
          </div>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam delectus maiores assumenda impedit optio totam
              molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eligendi impedit explicabo debitis
              laboriosam iste consequuntur accusamus cum, nobis repellendus,
              ipsum quibusdam pariatur expedita cumque minus.
            </p>
          </div>
          <div className="mt-4">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
              voluptates in sed, ipsum animi perferendis dolores recusandae,
              ipsa quidem quam delectus maiores assumenda impedit optio totam
              molestias, aspernatur id. Esse! Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eligendi impedit explicabo debitis
              laboriosam iste consequuntur accusamus cum, nobis repellendus,
              ipsum quibusdam pariatur expedita cumque minus.
            </p>
          </div>
        </Accordion>
      </HeadingCard>
    </Heading>
  );
};

export default UserGuide;
