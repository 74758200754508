import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "@/AuthContext";
import { useNavigate ,Redirect} from "react-router-dom";

export default function Login() {
  const {
    formState: { errors },
  } = useForm();
  const { login, auth } = useAuth();
  const methods = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(true);

  const navigate = useNavigate()

  // useEffect(() => {
  //   if ([null, undefined].includes(auth?.token) && redirect) {
  //     navigate('/');
  //     setRedirect(false)
  //   }
  // })

  const onSubmit = () => {
    const loginData = {
      username: email,
      password: password
    };

    axios.post(process.env.REACT_APP_BACKEND_URL + "/rest/V1/integration/admin/token", loginData)
      .then(response => {
        console.log("response.data", response.data);
        localStorage.setItem("token", response.data);
        login(response.data);
        navigate('/');
      })
      .catch(error => {
        console.error('There was an error logging in!', error);
      });
  };

  return (
    <>
      <div className={"max-w-[851px] mx-auto pt-12 pb-24 registering-wrapper-box"}>
        <h1 className={"text-[64px] text-[#00C0F3] font-bold text-center"}>
          Login
        </h1>

        <div className={"relative mb-12"}>
          <div
            className={
              "absolute my-auto top-0 bottom-0 left-0 h-0.5 w-full bg-[#D9D9D9]"
            }
          ></div>
        </div>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="space-y-6 register-col-input flex flex-col justify-center items-center">
              <input
                {...methods.register("email", {
                  required: "This field is required",
                })}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"Your Email"}
                className="p-5 w-full rounded-2xl"
              />
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}

              <input
                {...methods.register("password", {
                  required: "This field is required",
                })}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={"Password"}
                type="password"
                className="p-5 w-full rounded-2xl"
              />
              {errors.password && (
                <span className="text-red-500">{errors.password.message}</span>
              )}
            </div>

            <button
              className={
                "bg-[#00C0F3] rounded-3xl text-white text-[32px] w-full py-5 mt-10"
              }
            >
              Login
            </button>
          </form>
        </FormProvider>
      </div>
    </>
  );
}