import React from "react";
import upArrow from "../../assests/images/upArrow.png";
import downArrow from "../../assests/images/downArrow.png";

const TotalCard = ({ title, days, totalNumber, increment= false, decrement= false }) => {
  return (
    <div className="p-5 bg-white drop-shadow-md rounded-md flex flex-col justify-center items-start ">
      <div className="text-xl text-primary font-sf-pro-display">
        {title}
      </div>
      <div className="text-sm text-primary font-sf-pro-display">
        {days}
      </div>
      <div className="flex justify-between w-full items-center mt-4">
        <div className="text-[32px] xl:text-2xl 2xl:text-[32px] text-deepskyblue-100 font-sf-pro-display font-medium">
          {totalNumber}
        </div>
        {increment && <img src={upArrow} alt="increment" className="h-8 w-8" />}
        {decrement && (
          <img src={downArrow} alt="increment" className="h-8 w-8" />
        )}
      </div>
    </div>
  );
};

export default TotalCard;
